import React, { PropsWithChildren, useState } from "react";
import { cx, css } from "@emotion/css/macro";
import { sendEvent } from "@shared/utils/analytics";
import { DISCORD_INVITE_URL } from "../../utils/constants";

const joinClickEvent = "join-click";

export default function JoinLink({ children }: PropsWithChildren<{}>) {
  return (
    <a
      href={DISCORD_INVITE_URL}
      onClick={() => sendEvent(joinClickEvent, { inline: true })}
    >
      <span className={cx(styles.text)}>{children}</span>
    </a>
  );
}

const styles = {
  text: css`
    color: #1d9bf0;
  `,
};
