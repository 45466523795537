import React from "react";
import Text from "@shared/components/Text";
import { cx, css } from "@emotion/css/macro";
import {
  HEADER_FULL_HEIGHT,
  HEADER_SMALL_HEIGHT,
} from "@shared/components/header/StickyHeader";
import { until } from "@shared/styles/mediaQueries";
import Subscribe from "./Subscribe";

const heroline = "Unlock creator superpowers";
const heroline2 = "ditch platforms and exorbitant fees";
const subline = `NiftyBadger harnesses the power of web3 to grow your community. Create NFTs that give fans exclusive access to your content, set up a managed Discord server, start a livestream, and more - all for free.`;
function Hero() {
  return (
    <div className={cx(styles.container, styles.containerTablet)}>
      <div className={cx("container")}>
        <div className={cx(styles.gradientText)}>
          <Text
            type="h1"
            className={cx(styles.heroline1, styles.heroline1Tablet)}
          >
            {heroline}
          </Text>
          <Text
            type="h1"
            className={cx(styles.heroline2, styles.heroline2Tablet)}
          >
            {heroline2}
          </Text>
          <Text
            className={cx(styles.subtitle, styles.subtitleTablet)}
            type="subtitle"
          >
            {subline}
          </Text>
        </div>
        <Subscribe className={cx(styles.subscribe)} />
      </div>
    </div>
  );
}

const styles = {
  container: css`
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    margin-top: ${HEADER_FULL_HEIGHT}px;
    padding: 18vh 0 15vh 0;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0),
      rgb(7, 4, 59),
      rgb(7, 4, 59),
      rgb(7, 4, 59),
      rgba(0, 0, 0)
    );
    animation: gradient 10s linear infinite;
  `,
  gradientText: css`
    // background: radial-gradient(ellipse at top 10px right 10px, #fff, #1971ff);
    background: linear-gradient(180deg, #fff, #3bbbff);

    animation: gradient 10s linear infinite;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  `,
  containerTablet: until(
    "tablet",
    css`
      margin-top: ${HEADER_SMALL_HEIGHT}px;
      padding: 15vh 0 4vh 0;
    `
  ),
  heroline1: css`
    font-size: 72px;
    margin-bottom: 24px;
  `,
  heroline1Tablet: until(
    "tablet",
    css`
      font-size: 60px;
      margin-bottom: 12px;
    `
  ),
  heroline2: css`
    font-size: 48px;
    margin-bottom: 12px;
  `,
  heroline2Tablet: until(
    "tablet",
    css`
      font-size: 40px;
      line-height: 60px;
    `
  ),
  subtitle: css`
    font-size: 28px;
    line-height: 42px;
    margin-top: 24px;
    margin-bottom: 24px;
  `,
  subtitleTablet: until(
    "tablet",
    css`
      font-size: 24px;
      line-height: 36px;
    `
  ),
  superscript: css`
    vertical-align: super;
    font-size: 70%;
  `,
  subscribe: css`
    padding: 16px 0;
  `,
};

export default Hero;
