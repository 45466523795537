import React, { PropsWithChildren } from "react";
import { cx, css, ClassNamesArg } from "@emotion/css/macro";
import Button from "@shared/components/button/Button";
import colors from "@shared/styles/colors.scss";
import { sendEvent } from "@shared/utils/analytics";
import { DISCORD_INVITE_URL } from "../../utils/constants";
import DiscordSVG from "./DiscordSVG";

const discordClickEvent = "discord-click";

export default function DiscordButton({
  onClick,
  children,
  className,
}: PropsWithChildren<{ onClick?: Function; className?: ClassNamesArg }>) {
  return (
    <Button
      color="discord"
      icon={<DiscordSVG className="discord" size={24} />}
      href={DISCORD_INVITE_URL}
      target="blank"
      size="medium"
      className={cx(styles.discordContainer, className)}
      onClick={() => {
        sendEvent(discordClickEvent, {});
        if (onClick) onClick();
      }}
    >
      {children}
    </Button>
  );
}
const styles = {
  discordContainer: css`
    display: flex;
    justify-content: center;
    transition: all 0.1s ease-in-out;
    border-radius: 8px !important;
    .discord {
      stroke: #ffffff;
      stroke-width: 4px;
      fill: ${colors.discord};
    }
    &:hover {
      transform: scale(1.05);
      .discord {
        stroke-width: 0px;
        fill: #ffffff;
      }
    }
  `,
};
