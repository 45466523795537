import React, { useRef, useState } from "react";
import { cx, css } from "@emotion/css/macro";
import Footer from "@shared/components/Footer";
import FAQ from "./components/sections/FAQ";
import About from "./components/sections/About";
import JoinUs from "./components/sections/JoinUs";
import Hero from "./components/sections/Hero";
import Header from "./components/Header";
import Create from "./components/sections/Create";
import Events from "./components/sections/Events";
import Integrations from "./components/sections/Integrations";
import "./styles/App.css";
import "@shared/styles/index.scss";
import "./styles/overrides.scss";
import { sendEvent } from "@shared/utils/analytics";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { RGBAColor } from "@shared/components/ColorPicker";
import { useStateObject } from "@shared/utils/hooks";
import { scrollToRef } from "@shared/utils/utils";
import CTA from "./components/CTA";

export type BadgeDataType = {
  canvasSize?: number;
  customImg?: HTMLImageElement | null;
  customImgSrc?: string | null;
  fontFillColor?: RGBAColor;
  fontStrokeColor?: RGBAColor;
  bgColor?: RGBAColor;
  hugCustomImage?: boolean;
};

function App() {
  const notifySectionRef = useRef<HTMLDivElement>(null);
  const faqSectionRef = useRef<HTMLDivElement>(null);
  const aboutSectionRef = useRef<HTMLDivElement>(null);
  const createSectionRef = useRef<HTMLDivElement>(null);
  const eventsSectionRef = useRef<HTMLDivElement>(null);
  const integrationsSectionRef = useRef<HTMLDivElement>(null);
  const ctaSectionRef = useRef<HTMLDivElement>(null);
  const headerRef = useRef<HTMLDivElement>(null);
  // TODO: hacky, should pull this out into redux
  const [badgeData, setBadgeData] = useStateObject<BadgeDataType>({});

  sendEvent("page-load", {});

  return (
    <DndProvider backend={HTML5Backend}>
      <div className={cx(styles.root)}>
        <Header
          ref={headerRef}
          faqSectionRef={faqSectionRef}
          notifySectionRef={notifySectionRef}
          aboutSectionRef={aboutSectionRef}
          createSectionRef={createSectionRef}
        />
        <Hero />
        <div className={cx(styles.spacerBlack)} />
        <Create
          ref={createSectionRef}
          onNext={() => {
            scrollToRef(eventsSectionRef, headerRef);
          }}
          setBadgeData={setBadgeData}
        />
        <Events
          ref={eventsSectionRef}
          onNext={() => {
            scrollToRef(integrationsSectionRef, headerRef);
          }}
          badgeData={badgeData}
        />
        <Integrations ref={integrationsSectionRef} badgeData={badgeData} />
        <CTA ref={ctaSectionRef} />
        <div className={cx("container", styles.container)}>
          <FAQ className={cx(styles.section)} ref={faqSectionRef} />
          <About className={cx(styles.section)} ref={aboutSectionRef} />
          <JoinUs className={cx(styles.section)} ref={notifySectionRef} />
          <Footer />
        </div>
      </div>
    </DndProvider>
  );
}

const styles = {
  root: css``,
  container: css`
    display: flex;
    align-items: center;
    flex-direction: column;
  `,
  section: css`
    width: 100%;
    margin-bottom: 64px;
  `,
  spacerBlack: css`
    height: 300px;
    background-color: black;
  `,
};

export default App;
