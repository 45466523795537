import { useRef, useCallback, useEffect, useState } from "react";

export const useCanvas = (
  draw?: (context: CanvasRenderingContext2D, frameCount: number) => void,
  options = {}
) => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);

  useEffect(() => {
    const canvas = canvasRef.current as HTMLCanvasElement;
    const ctx = canvas.getContext("2d");

    // Get the device pixel ratio, falling back to 1.
    var dpr = window.devicePixelRatio || 1;
    // Get the size of the canvas in CSS pixels.
    var rect = canvas.getBoundingClientRect();
    // Give the canvas pixel dimensions of their CSS
    // size * the device pixel ratio.
    canvas.width = rect.width * dpr;
    canvas.height = rect.height * dpr;

    let frameCount = 0;
    let animationFrameId: number;
    if (ctx != null) {
      // Scale all drawing operations by the dpr, so you
      // don't have to worry about the difference.
      ctx?.scale(dpr, dpr);
      if (draw != null) {
        const render = () => {
          frameCount++;
          draw(ctx, frameCount);
          animationFrameId = window.requestAnimationFrame(render);
        };
        render();
      }
    }
    return () => {
      window.cancelAnimationFrame(animationFrameId);
    };
  }, [draw]);
  return canvasRef;
};

// Improved version of https://usehooks.com/useOnClickOutside/
export const useClickOutside = (ref: any, handler: any) => {
  useEffect(() => {
    let startedInside = false;
    let startedWhenMounted = false;

    const listener = (event: any) => {
      // Do nothing if `mousedown` or `touchstart` started inside ref element
      if (startedInside || !startedWhenMounted) return;
      // Do nothing if clicking ref's element or descendent elements
      if (!ref.current || ref.current.contains(event.target)) return;

      handler(event);
    };

    const validateEventStart = (event: any) => {
      startedWhenMounted = ref.current;
      startedInside = ref.current && ref.current.contains(event.target);
    };

    document.addEventListener("mousedown", validateEventStart);
    document.addEventListener("touchstart", validateEventStart);
    document.addEventListener("click", listener);

    return () => {
      document.removeEventListener("mousedown", validateEventStart);
      document.removeEventListener("touchstart", validateEventStart);
      document.removeEventListener("click", listener);
    };
  }, [ref, handler]);
};

export function useStateObject<T>(defaultValue: T): [T, (obj: T) => void] {
  const [objData, setObjData] = useState<T>(defaultValue);

  useEffect(() => {
    return () => {
      // do cleanup
    };
  }, [objData]);

  const customSetObject = useCallback((newObjData: T) => {
    setObjData((oldObjData) => {
      return {
        ...oldObjData,
        ...newObjData,
      };
    });
  }, []);

  return [objData, customSetObject];
}
