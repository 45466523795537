import React, { forwardRef } from "react";
import Text from "@shared/components/Text";
import Expandable from "@shared/components/expandable/Expandable";
import { cx, css, ClassNamesArg } from "@emotion/css/macro";
import * as LaunchStrings from "../../utils/faqStrings";

type Props = { className: ClassNamesArg };
const FAQ = forwardRef<HTMLDivElement, Props>(({ className }, ref) => (
  <div ref={ref} className={cx(className, styles.container)}>
    <Text type={"h2"}>F.A.Q</Text>
    {Object.keys(LaunchStrings.faq).map((qa, idx) => (
      <Expandable key={`launchstring-${idx}`} title={LaunchStrings.faq[qa].q}>
        {LaunchStrings.faq[qa].a.map((text, idx) => (
          <Text
            key={`answer-${idx}`}
            type={"markdown"}
            className={cx(styles.paragraph)}
          >
            {text}
          </Text>
        ))}
      </Expandable>
    ))}
  </div>
));

const styles = {
  container: css``,
  paragraph: css`
    margin-bottom: 12px;
  `,
};

export default FAQ;
