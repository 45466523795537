// @ts-nocheck
import * as React from "react";

const SvgComponent = (props) => (
  <svg
    width={props.size}
    height={props.size}
    className={`badger-stripe ${props.className || null}`}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 101 88"
    style={{
      enableBackground: "new 0 0 101 88",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <path
      d="M100.5 69.4 83.8 86.8c-.4.4-.8.7-1.3.9-.5.2-1 .3-1.5.3H2c-.4 0-.7-.1-1.1-.3-.3-.2-.6-.5-.7-.8-.2-.4-.2-.7-.2-1.1.1-.4.2-.7.5-1l16.7-17.4c.4-.4.8-.7 1.3-.9.5-.2 1-.3 1.5-.3h79c.4 0 .7.1 1.1.3.3.2.6.5.7.8.2.3.2.7.1 1.1s-.2.7-.4 1zM83.8 34.3c-.4-.4-.8-.7-1.3-.9-.5-.2-1-.3-1.5-.3H2c-.4 0-.7.1-1.1.3-.3.2-.6.5-.7.8-.2.4-.2.7-.2 1.1.1.4.2.7.5 1l16.7 17.4c.4.4.8.7 1.3.9.5.2 1 .3 1.5.3h79c.4 0 .7-.1 1.1-.3.3-.2.6-.5.7-.8.2-.3.2-.7.1-1.1-.1-.4-.2-.7-.5-1L83.8 34.3zM1.9 21.8h79c.5 0 1.1-.1 1.5-.3.5-.2.9-.5 1.3-.9l16.7-17.4c.3-.3.4-.6.5-1s0-.7-.1-1.1c-.2-.3-.4-.6-.7-.8-.3-.2-.7-.3-1-.3H20c-.5 0-1.1.1-1.5.3-.5.2-.9.5-1.3.9L.5 18.6c-.3.3-.4.6-.5 1-.1.4 0 .7.1 1.1.1.3.4.6.7.8.4.2.8.3 1.1.3z"
      style={{
        fill: "#fff",
      }}
    />
  </svg>
);

export default SvgComponent;
