import React, { forwardRef } from "react";
import { cx, css, ClassNamesArg } from "@emotion/css/macro";
import { until } from "@shared/styles/mediaQueries";
import Text from "@shared/components/Text";

import DiscordButton from "./discord/DiscordButton";

const CTA = forwardRef<HTMLDivElement, {}>(({}, ref) => (
  <div ref={ref} className={cx(styles.wrapper, styles.ideas)}>
    <div className={cx(styles.text)}>
      <Text type="subtitle">
        Have ideas for more integrations? Care to join our beta testers? Think
        this is awesome?
      </Text>
    </div>
    <DiscordButton>
      <Text type="subtitle">What are you waiting for?</Text>
    </DiscordButton>
  </div>
));

const styles = {
  wrapper: css`
    width: 80%;
    border: 1px solid white;
    border-top: 0px;
    margin: auto;
    margin-top: 0px;
    margin-bottom: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0px 0px 15px 5px rgb(10 10 10 / 10%);
    padding: 32px;
  `,
  ideas: css`
    display: flex;
    flex-direction: column;
    justify-content: center;
  `,
  text: css`
    margin-bottom: 16px;
  `,
};

export default CTA;
