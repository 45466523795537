import React, { PropsWithChildren, useState } from "react";
import { cx, css } from "@emotion/css/macro";
import { sendEvent } from "@shared/utils/analytics";
import { FOLLOW_US_URL } from "../../utils/constants";

const followClickEvent = "follow-click";

export default function FollowLink({
  children,
}: PropsWithChildren<{ inline?: boolean }>) {
  return (
    <a
      href={FOLLOW_US_URL}
      onClick={() => sendEvent(followClickEvent, { inline: true })}
    >
      <span className={cx(styles.twitterText)}>{children}</span>
    </a>
  );
}

const styles = {
  twitterText: css`
    color: #1d9bf0;
  `,
};
