import React from "react";
import Text from "@shared/components/Text";
import { cx, css } from "@emotion/css/macro";
import { Linkedin, Twitter, GitHub } from "react-feather";
import { sendEvent } from "@shared/utils/analytics";

interface ProfileProps {
  linkedinUrl: string;
  twitterUrl: string;
  githubUrl: string;
  profileImg: string;
  title: string;
  body: string;
}

const profileClickEvent = "profile-click";

export default function ProfileCard(props: ProfileProps) {
  return (
    <>
      <div className={cx(styles.cardContainer)}>
        <img
          className={cx(styles.image)}
          src={props.profileImg}
          alt={"profile"}
        />
        <div className={cx(styles.social)}>
          <a
            onClick={() =>
              sendEvent(profileClickEvent, {
                name: props.title,
                type: "linkedin",
              })
            }
            href={props.linkedinUrl}
            title="LinkedIn"
          >
            <Linkedin fill="#0077b5" fillOpacity={0.89} />
          </a>
          <a
            onClick={() =>
              sendEvent(profileClickEvent, {
                name: props.title,
                type: "twitter",
              })
            }
            href={props.twitterUrl}
            title="Twitter"
          >
            <Twitter fill="#1d9bf0" fillOpacity={0.89} />
          </a>
          <a
            onClick={() =>
              sendEvent(profileClickEvent, {
                name: props.title,
                type: "github",
              })
            }
            href={props.githubUrl}
            title="Github"
          >
            <GitHub fill="white" fillOpacity={0.89} />
          </a>
        </div>
        <Text className={cx(styles.text)} type="subtitle">
          {props.title}
        </Text>
        <Text className={cx(styles.text)} type="body">
          {props.body}
        </Text>
      </div>
    </>
  );
}

const styles = {
  cardContainer: css`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 300px;
    padding-left: 3em;
    padding-right: 3em;
    padding-top: 1em;
  `,
  image: css`
    width: 150px;
    padding: 0.5em;
    border-radius: 2em;
  `,
  social: css`
    width: 100px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  `,
  text: css`
    text-align: center;
  `,
};
