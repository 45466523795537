import { RGBAColor } from "../components/ColorPicker";
export function scrollToRef(
  sectionRef: React.RefObject<HTMLElement>,
  offsetRef: React.RefObject<HTMLElement>,
  offsetMargin: number = 24
) {
  if (sectionRef.current != null) {
    const headerOffset =
      (offsetRef.current &&
        offsetRef.current.getBoundingClientRect().height + offsetMargin) ||
      0;

    // we have to subtract the faq section from body because it's
    // relative to viewport
    const faqPosition = getRefTop(sectionRef);
    window.scrollTo({
      top: faqPosition - headerOffset,
      behavior: "smooth",
    });
  }
}

export function getRefTop(sectionRef: React.RefObject<HTMLElement>) {
  if (sectionRef.current == null) return 0;
  // we have to subtract the faq section from body because it's
  // relative to viewport
  return (
    sectionRef.current.getBoundingClientRect().top -
    document.body.getBoundingClientRect().top
  );
}

export function RGBAColorToString(color: RGBAColor): string {
  return `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`;
}
