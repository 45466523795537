import { cx, css, ClassNamesArg } from "@emotion/css/macro";
import { until } from "@shared/styles/mediaQueries";
import Text from "@shared/components/Text";
import TwitterButton from "../twitter/TwitterButton";
import DiscordButton from "../discord/DiscordButton";

export default function Subscribe({
  className,
}: {
  className?: ClassNamesArg;
}) {
  return (
    <div className={cx(styles.content, styles.contentVertical, className)}>
      <TwitterButton action="subscribe">
        <Text type="subtitle">Share with a @creator</Text>
      </TwitterButton>
      <span className={cx(styles.spacer, styles.spacerVertical)}></span>
      {/* <span className={cx(styles.line, styles.lineVertical)}></span> */}
      <span className={cx(styles.spacer, styles.spacerVertical)}></span>
      <DiscordButton>
        <Text type="subtitle">Join our Discord</Text>
      </DiscordButton>
      {/* <TwitterButton light action="follow">
        <Text type="subtitle">Follow our journey</Text>
      </TwitterButton> */}
    </div>
  );
}
const styles = {
  twitterContainer: css`
    display: flex;
    justify-content: center;
    transition: all 0.1s ease-in-out;
    &:hover {
      transform: scale(1.05);
      .twitter-heart {
        stroke: #ffffff;
      }
    }
  `,
  content: css`
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    align-items: center;
    font-size: 18px;
    font-family: "Itim", cursive;
  `,
  title: css`
    line-height: 80px;
  `,
  spacer: css`
    width: 25px;
  `,
  line: css`
    background-color: black;
    width: 1px;
    height: 50px;
  `,
  lineVertical: until(
    "tablet",
    css`
      height: 0px;
    `
  ),
  spacerVertical: until(
    "tablet",
    css`
      height: 0.75em;
    `
  ),
  contentVertical: until(
    "tablet",
    css`
      flex-direction: column;
      justify-content: space-evenly;
    `
  ),
};
