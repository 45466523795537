import axios, { AxiosInstance } from "axios";

const axiosClient = axios.create({
  baseURL: `${process.env.REACT_APP_SERV_PROTOCOL}${process.env.REACT_APP_SERV_HOSTNAME}/api`,
  headers: {
    "X-Requested-With": "XMLHttpRequest",
  },
});

export const APIClient = (): AxiosInstance => {
  return axiosClient;
};
