import React, { useCallback, useState } from "react";
import { cx, css, ClassNamesArg } from "@emotion/css";
import { useThrottleCallback } from "@react-hook/throttle";
import { DropTarget } from "./DropTarget";

type Props = {
  id?: string;
  name?: string;
  dropTarget: JSX.Element;
  className?: ClassNamesArg;
  onError: (error: DropTargetError) => void;
  onSuccess: (file: File) => void;
  onIsActiveChanged: (isActive: boolean) => void;
};

export enum DropTargetError {
  TOO_MANY_FILES = "TOO_MANY_FILES",
  UNKNOWN_ERROR = "UNKNOWN_ERROR",
}

function DndFileUploader({
  dropTarget,
  onSuccess,
  onError,
  onIsActiveChanged,
  className,
  ...props
}: Props) {
  return (
    <DropTarget
      className={className}
      dropTargetElement={dropTarget}
      onError={onError}
      onSuccess={onSuccess}
      onIsActiveChanged={onIsActiveChanged}
    />
  );
}

export default DndFileUploader;
