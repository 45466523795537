import React, { PropsWithChildren, Dispatch, useState } from "react";
import { cx, css } from "@emotion/css/macro";
import Text from "../Text";
import * as Icon from "react-feather";

import { animated, useSpring } from "@react-spring/web";
import { sendEvent } from "../../utils/analytics";

const analyticsClickEvent = "expandable-click";

type ExpandableHeaderProps = {
  title: string;
  expanded: boolean;
  setExpanded: Dispatch<boolean>;
};
function ExpandableHeader({
  title,
  expanded,
  setExpanded,
}: ExpandableHeaderProps) {
  return (
    <a
      href="#"
      onClick={(e) => {
        e.preventDefault();
        setExpanded(!expanded);
        sendEvent(analyticsClickEvent, {
          title: title,
          expanded: expanded,
        });
      }}
      className={cx(styles.header)}
    >
      <Text className={cx(styles.title)} type="body">
        {title}
      </Text>

      <span className={"icon"}>
        <Icon.PlusCircle />
      </span>
    </a>
  );
}

type ExpandableContentProps = PropsWithChildren<{
  expanded: boolean;
  setExpanded: Dispatch<boolean>;
}>;
function ExpandableContent({ children, expanded }: ExpandableContentProps) {
  const elementRef = React.useRef<HTMLDivElement>(null);
  const spring = useSpring({
    maxHeight: expanded ? `${elementRef.current?.clientHeight || 0}px` : "0",
    opacity: expanded ? 1 : 0,
    config: { duration: 200 },
  });
  return (
    <animated.div style={spring} className={cx(styles.contentWrapper)}>
      <div className={cx(styles.content)} ref={elementRef}>
        {children}
      </div>
    </animated.div>
  );
}

type Props = PropsWithChildren<{ title: string }>;
function Expandable({ title, children }: Props) {
  const [expanded, setExpanded] = useState<boolean>(false);

  return (
    <div className={cx(styles.expandable)}>
      <ExpandableHeader
        title={title}
        expanded={expanded}
        setExpanded={setExpanded}
      ></ExpandableHeader>
      <ExpandableContent expanded={expanded} setExpanded={setExpanded}>
        {children}
      </ExpandableContent>
    </div>
  );
}

const styles = {
  header: css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 80px;
  `,
  headerIcon: css``,
  content: css`
    width: 100%;
    padding-bottom: 24px;
  `,
  contentWrapper: css`
    position: relative;
    display: flex;
    align-items: flex-start;
    overflow: hidden;
  `,
  title: css`
    font-weight: 500;
  `,
  expandable: css`
    padding: 12px;
    &:not(:last-child) {
      border-bottom: 1px solid black;
    }
  `,
};

export default Expandable;
