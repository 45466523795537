import React, { PropsWithChildren } from "react";
import { cx, css } from "@emotion/css/macro";
import * as Icon from "react-feather";
import Button from "@shared/components/button/Button";
import colors from "@shared/styles/colors.scss";
import { sendEvent } from "@shared/utils/analytics";
import { FOLLOW_US_URL, TWEET_AT_CREATORS_URL } from "../../utils/constants";
const tweetClickEvent = "tweet-click";
const followClickEvent = "follow-click";

type Props = {
  light?: boolean;
  action: string;
};

export default function TwitterButton({
  children,
  action,
  light = false,
}: PropsWithChildren<Props>) {
  return !light ? (
    <Button
      color="twitter"
      icon={
        <Icon.Twitter className="twitter-share" color={colors.twitterWhite} />
      }
      size="medium"
      href={TWEET_AT_CREATORS_URL}
      target="blank"
      className={cx(styles.twitterContainer)}
      onClick={() => sendEvent(tweetClickEvent, { action })}
    >
      {children}
    </Button>
  ) : (
    <Button
      color="twitter"
      icon={
        <Icon.Heart className="twitter-heart" color={colors.twitterLight} />
      }
      inverted
      light
      size="medium"
      href={FOLLOW_US_URL}
      target="blank"
      className={cx(styles.twitterContainer)}
      onClick={() => sendEvent(followClickEvent, { inline: false })}
    >
      {children}
    </Button>
  );
}
const styles = {
  twitterContainer: css`
    display: flex;
    justify-content: center;
    transition: all 0.1s ease-in-out;
    border-radius: 8px !important;
    &:hover {
      transform: scale(1.05);
      .twitter-heart {
        stroke: ${colors.twitterHeart};
        fill: ${colors.twitterHeart};
      }

      .twitter-share {
        stroke: ${colors.twitterWhite};
        fill: ${colors.twitterWhite};
      }
    }
  `,
};
