import React, { forwardRef } from "react";
import { cx, css, ClassNamesArg } from "@emotion/css/macro";
import { until } from "@shared/styles/mediaQueries";
import Text from "@shared/components/Text";
import Button from "@shared/components/button/Button";
import colors from "@shared/styles/colors.scss";
import CanvasImage from "./Create/CanvasImage";
import BadgerIcon from "@shared/components/logo/BadgerIcon";
import StripeSVG from "@shared/components/SVGAssets/StripeSVG";
import Solana from "@shared/components/SVGAssets/Solana";

import { BadgeDataType } from "../../App";
import DiscordButton from "../discord/DiscordButton";

type Props = { badgeData: BadgeDataType };
const Integrations = forwardRef<HTMLDivElement, Props>(({ badgeData }, ref) => (
  <div ref={ref} className={cx(styles.wrapper)}>
    <div ref={ref} className={cx(styles.container)}>
      <Text type="h3">Badge in hand? Welcome to the party.</Text>
      <Text type="subtitle">
        Augment your community with powerful integrations into your favorite
        services.
      </Text>
      <div className={cx(styles.contentWrapper)}>
        <div className={cx(styles.existing)}>
          <div className={cx(styles.services)}>
            <div className="badger-mint">
              <div className="badger-mint-demo">
                <CanvasImage
                  size={300}
                  hugCustomImage={badgeData.hugCustomImage}
                  customImg={badgeData.customImg}
                  customImgSrc={badgeData.customImgSrc}
                  legendary
                  imgNumber={1}
                  fontStrokeColor={{
                    r: 255,
                    g: 255,
                    b: 255,
                    a: 1,
                  }}
                  fontFillColor={{
                    r: 0,
                    g: 0,
                    b: 0,
                    a: 1,
                  }}
                  paddingColor={{
                    r: 255,
                    g: 255,
                    b: 255,
                    a: 1,
                  }}
                />
                <div className="badger-mint-buttons">
                  <Button
                    color="twitter"
                    state="focused"
                    className={cx(styles.mintButton)}
                    icon={<BadgerIcon size={20} inverted />}
                    size="medium"
                    onClick={() => {}}
                  >
                    <Text
                      className={cx(styles.stripButtonText)}
                      type="subtitle"
                    >
                      Buy with
                      <StripeSVG size={60} />
                    </Text>
                  </Button>
                  <Text> or </Text>
                  <Button
                    color="twitter"
                    state="focused"
                    className={cx(styles.mintButton)}
                    icon={<BadgerIcon size={20} inverted />}
                    size="medium"
                    onClick={() => {}}
                  >
                    <Text
                      className={cx(styles.stripButtonText)}
                      type="subtitle"
                    >
                      Mint with
                      <Solana className={cx(styles.solana)} size={32} />
                    </Text>
                  </Button>
                </div>
              </div>
              <div className="badger-mint-description">
                <Text>
                  No crypto? No problem - purchase with Stripe and be on your
                  way
                </Text>
              </div>
            </div>
            <div className={styles.more}>
              <div className="badger-integrations-more-icons">
                <img className="shopify" src="/shopify.png" />
                <img className="twitch" src="/twitch.png" />
                <img className="polygon" src="/polygon.webp" />
              </div>
              <Text>A few teasers for the future</Text>
            </div>
          </div>
          <div className={styles.discord}>
            <img src="/discord_integration.png" />
            <Text>Our Discord BadgerBot checks badges at the door</Text>
          </div>
        </div>
      </div>
    </div>
  </div>
));

const styles = {
  wrapper: css`
    background-color: ${colors.badgerBlue};
    color: white;
    border-top: 1px solid #efefef;
    margin: auto;
    padding: 72px 0;
  `,
  container: css`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  `,
  contentWrapper: css`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 64px;
  `,
  services: css`
    text-align: center;
    & > .badger-mint {
      transition: transform 0.25s;
      &:hover {
        transform: scale(1.1);
      }
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    & .badger-mint-demo {
      background-color: white;
      padding: 32px;
      border-radius: 16px;
      pointer-events: none;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    & .badger-mint-buttons {
      margin-top: 24px;
      align-items: center;
      color: black;
      display: flex;
      justify-content: space-between;
      & > .badger-text {
        margin: 0 12px;
      }
    }
  `,
  mintButton: css`
    min-width: 240px;

    background: rgb(28, 44, 66); /* Old browsers */
    background: -moz-radial-gradient(
      center,
      ellipse cover,
      #1e5799 0%,
      rgb(28, 44, 66) 100%
    ); /* FF3.6+ */
    background: -webkit-gradient(
      radial,
      center center,
      0px,
      center center,
      100%,
      color-stop(0%, #1e5799),
      color-stop(100%, rgb(28, 44, 66))
    ); /* Chrome,Safari4+ */
    background: -webkit-radial-gradient(
      center,
      ellipse cover,
      #1e5799 0%,
      rgb(28, 44, 66) 100%
    ); /* Chrome10+,Safari5.1+ */
    background: -o-radial-gradient(
      center,
      ellipse cover,
      #1e5799 0%,
      rgb(28, 44, 66) 100%
    ); /* Opera 12+ */
    background: -ms-radial-gradient(
      center,
      ellipse cover,
      #1e5799 0%,
      rgb(28, 44, 66) 100%
    ); /* IE10+ */
    background: radial-gradient(
      ellipse at center,
      #1e5799 0%,
      rgb(28, 44, 66) 100%
    ); /* W3C */

    -webkit-animation: filter-animation 3s infinite;
    animation: filter-animation 3s infinite;
  `,
  stripButtonText: css`
    display: flex;
    align-items: center;
  `,
  solana: css`
    margin-left: 8px;
  `,
  discord: css`
    margin-left: 48px;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: transform 0.25s;
    margin-top: 64px;
    &:hover {
      transform: scale(1.1);
    }
    & > img {
    }
    & > .badger-text {
      max-width: 400px;
      text-align: center;
    }
  `,
  existing: css`
    display: flex;
  `,
  more: css`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 48px;
    transition: transform 0.25s;
    &:hover {
      transform: scale(1.1);
    }

    & > .badger-integrations-more-icons {
      position: relative;
      background-color: white;
      width: 320px;
      height: 320px;
      border-radius: 16px;
    }

    & > .badger-integrations-more-icons > img {
      display: block;
      max-width: 96px;
      max-height: 96px;
      position: absolute;
      &.polygon {
        top: 40px;
        left: 40px;
      }
      &.twitch {
        top: 190px;
        left: 60px;
      }
      &.shopify {
        top: 80px;
        left: 200px;
      }
    }
  `,
};

export default Integrations;
