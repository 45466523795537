import React, { forwardRef } from "react";
import { cx, css, ClassNamesArg } from "@emotion/css/macro";
import { until } from "@shared/styles/mediaQueries";
import Text from "@shared/components/Text";
import TextArea from "@shared/components/inputs/TextArea";
import Input from "@shared/components/inputs/Input";
import CanvasImage from "./Create/CanvasImage";
import * as Icon from "react-feather";
import colors from "@shared/styles/colors.scss";

import { BadgeDataType } from "../../App";
type Props = { badgeData: BadgeDataType; onNext: () => void };
const Events = forwardRef<HTMLDivElement, Props>(
  ({ badgeData, onNext }, ref) => (
    <div ref={ref} className={cx(styles.eventsWrapper)}>
      <Text type="h3">Power up your community 🚀</Text>
      <Text type="subtitle">
        Create an event with just a few clicks - we'll help you build the hype.
      </Text>
      <div className={cx(styles.events)}>
        <div className={cx(styles.basicInfo)}>
          <div className={cx(styles.badgeInfo)}>
            <CanvasImage
              size={150}
              hugCustomImage={badgeData.hugCustomImage}
              customImg={badgeData.customImg}
              customImgSrc={badgeData.customImgSrc}
              legendary
              paddingColor={{
                r: 255,
                g: 255,
                b: 255,
                a: 1,
              }}
            />
            <p>
              <b>250</b> total badges
            </p>
            <div className={cx(styles.badgeAddCollection)}>
              <Icon.Plus size={18} />
              <p>Add collection</p>
            </div>
          </div>
          <div className={cx("badger-events-basicInfo-inputs")}>
            <div>
              <h3>Event Title </h3>
              <Input
                className="badger-events-basicInfo-inputs-title"
                value="The NiftyBadger Launch Party"
              />
            </div>
            <div>
              <h3>Description </h3>
              <TextArea
                fixedSize
                value="Come join our launch party! Badge holders will get exclusive access to our secret discord channels and our first wave of upcoming merch."
              />
            </div>
            <div>
              <div className={cx(styles.start)}>
                <div className={cx(styles.dateInput)}>
                  <Icon.Calendar />
                  <div>
                    <h1>Start Date </h1>
                    <p>Mar 3, 2022</p>
                  </div>
                </div>
                <div className={cx(styles.dateInput)}>
                  <Icon.Calendar color="#787878" />
                  <div>
                    <h1>Start Time </h1>
                    <p>10:00 AM</p>
                  </div>
                </div>
              </div>
              <span className={cx(styles.end)}>+ End Date and Time</span>
            </div>
          </div>
        </div>
        <div className={cx(styles.location)}>
          <div className={cx(styles.locationPicker)}>
            <Icon.CheckCircle className="badge-location-selector" />
            <Icon.LogIn className="badge-location-type" />
            <div className="badger-locationPicker-info">
              <h1>
                <b>Virtual Event</b>
              </h1>
              <p>Your exclusive event access page for badge holders only</p>
            </div>
          </div>
          <div className={cx(styles.locationPicker, "disabled")}>
            <Icon.Circle className="badge-location-selector" />
            <Icon.MapPin className="badge-location-type" />
            <div className="badger-locationPicker-info">
              <h1>
                <b>Physical Event</b> <span>(coming soon...)</span>
              </h1>
              <p>
                We're working hard to bring nifty badges into the real world!
              </p>
            </div>
          </div>
        </div>
      </div>
      <span
        className={cx(styles.next)}
        onClick={() => {
          onNext();
        }}
      >
        <Icon.ChevronsDown size={30} />
      </span>
    </div>
  )
);

const styles = {
  interested: css`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-top: 2em;
  `,
  spacer: css`
    width: 2em;
  `,
  spacerVertical: until(
    "tablet",
    css`
      height: 1em;
    `
  ),
  interestedVertical: until(
    "tablet",
    css`
      flex-direction: column;
      justify-content: space-evenly;
    `
  ),
  events: css`
    margin-top: 32px;
    justify-content: center;
    width: 800px;
    display: flex;
    flex-direction: column;
    align-items: center;
    pointer-events: none;
  `,
  eventsWrapper: css`
    width: 80%;
    border: 1px solid white;
    margin: 32px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0px 0px 15px 5px rgb(10 10 10 / 10%);
    border-radius: 40px;
    padding: 32px 150px;
  `,
  basicInfo: css`
    display: flex;
    align-items: center;
    width: 100%;
    & > .badger-events-basicInfo-inputs {
      flex-grow: 1;
      margin-left: 40px;
      & .badger-events-basicInfo-inputs-title {
        width: 50%;
      }
      & > div {
        margin-bottom: 12px;
      }
      h3 {
        margin-bottom: 4px;
      }
    }
  `,
  badgeAddCollection: css`
    margin-top: 24px;
    display: flex;
    font-size: 14px;
    align-items: center;
    justify-content: center;
    border: 1px solid black;
    width: 150px;
    height: 48px;
    border-radius: 10px;
    & > p {
      margin-top: 1px;
    }
  `,
  badgeInfo: css`
    display: flex;
    flex-direction: column;
    align-items: center;
    & > p {
      margin-top: 16px;
    }
  `,
  start: css`
    display: flex;
    & > * {
      flex: 1;
    }
    & > div:first-child {
      margin-right: 24px;
    }

    & h1 {
      font-size: 14px;
      color: grey;
    }
    & p {
      font-size: 16px;
      font-weight: 1000;
    }
  `,

  end: css`
    color: ${colors.badgerBlue};
    font-weight: 800;
    font-size: 12px;
  `,

  dateInput: css`
    display: flex;
    align-items: center;
    border: 1px solid #dbdbdb;
    border-radius: 4px;
    padding: 10px 18px;
    & > svg {
      margin-right: 14px;
    }
  `,
  location: css`
    display: flex;
    width: 100%;
    & > div {
      flex: 1;
    }
  `,
  locationPicker: css`
    display: flex;
    align-items: flex-start;
    padding: 24px 24px;
    border-radius: 8px;
    position: relative;
    color: #787878;
    border: 2px solid #787878;
    &.disabled {
      opacity: 0.4;
      border: 2px solid #dbdbdb;

      .badge-location-selector,
      .badge-location-type {
        stroke: #dbdbdb;
      }
    }
    &:not(.disabled) {
      color: ${colors.badgerBlue};
    }
    & > .badge-location-selector {
      position: absolute;
      flex-shrink: 0;
      right: 24px;
      top: 24px;
      width: 16px;
      height: 16px;
    }
    & .badge-location-type {
      flex-shrink: 0;
      width: 24px;
      height: 24px;
      margin-right: 16px;
      margin-top: 4px;
      stroke: #787878;
    }
    &:first-child {
      margin-right: 24px;
    }
    & > .badger-locationPicker-info {
      & > h1 {
        font-size: 1.1em;
        margin-bottom: 4px;
      }
      & > p {
        max-width: 280px;
      }
      display: flex;
      flex-direction: column;
      font-size: 14px;
    }
  `,
  next: css`
    margin-top: 12px;
    width: 100%;
    text-align: center;
    animation-duration: 1.6s;
    animation-name: iconArrowBounceDownBlack;
    animation-iteration-count: infinite;
    &:hover {
      cursor: pointer;
    }
  `,
};

export default Events;
