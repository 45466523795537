export const BADGER_ICON_URL = "/niftybadger-icon-lg.png";
export const BORDER_LEGENDARY_SVG_URL = "/border-legendary.svg";
export const BORDER_GENERIC_SVG_URL = "/border-generic.svg";
export const TWEET_AT_US_URL =
  "https://twitter.com/intent/tweet?text=Hey%20%40niftybadger!";
export const FOLLOW_US_URL =
  "https://twitter.com/intent/follow?screen_name=niftybadger";
export const TWEET_AT_CREATORS_URL =
  "https://twitter.com/intent/tweet?text=Hey%20%40niftybadger!%20You%20should%20get%20in%20touch%20with...";
export const DISCORD_INVITE_URL = "https://discord.gg/s7aCYxSw";
export const CANVAS_SIZE_FOR_TABLET = 150;
export const CANVAS_SIZE_FOR_ALL_ELSE = 125;
