import React, { forwardRef } from "react";
import { cx, css, ClassNamesArg } from "@emotion/css/macro";
import { until } from "@shared/styles/mediaQueries";
import Text from "@shared/components/Text";
import TwitterButton from "../twitter/TwitterButton";
import FollowLink from "../twitter/Follow";
import JoinLink from "../discord/JoinLink";

type Props = { className: ClassNamesArg };
const JoinUs = forwardRef<HTMLDivElement, Props>(({ className }, ref) => (
  <div ref={ref} className={cx(className, styles.container)}>
    <Text className={cx(styles.header)} type="h3">
      Join the community
    </Text>
    <Text type="body">
      If you've read this far, you must be excited for the same reasons we are -
      we'd love to have you along for our mission to empower creators and their
      communities. <FollowLink inline>Follow @niftybadger</FollowLink> and{" "}
      <JoinLink>join our Discord server</JoinLink> for updates and watch for our
      go live announcement in March!
    </Text>
    <div className={cx(styles.interested, styles.interestedVertical)}>
      <Text type="subtitle">Know a creator who might be interested?</Text>
      <span className={cx(styles.spacer, styles.spacerVertical)}></span>
      <TwitterButton action="join">
        <Text type="subtitle">Connect us!</Text>
      </TwitterButton>
    </div>
  </div>
));

const styles = {
  container: css``,
  header: css`
    margin-bottom: 24px;
  `,
  interested: css`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-top: 2em;
  `,
  spacer: css`
    width: 2em;
  `,
  spacerVertical: until(
    "tablet",
    css`
      height: 1em;
    `
  ),
  interestedVertical: until(
    "tablet",
    css`
      flex-direction: column;
      justify-content: space-evenly;
    `
  ),
};

export default JoinUs;
