import React, {
  forwardRef,
  useState,
  useEffect,
  useRef,
  useCallback,
} from "react";
import { cx, css, ClassNamesArg } from "@emotion/css/macro";
import Text from "@shared/components/Text";
import CanvasImage from "./CanvasImage";
import ColorPicker, { RGBAColor } from "@shared/components/ColorPicker";
import InlineInput from "@shared/components/inputs/InlineInput";
import Checkbox from "@shared/components/inputs/Checkbox";

import { until } from "@shared/styles/mediaQueries";
import { BadgeDataType } from "../../../App";
type Props = {
  customImg: HTMLImageElement | null;
  numBadges: number;
  canvasSize: number;
  setBadgeData: (obj: BadgeDataType) => void;
};

function draw(context: CanvasRenderingContext2D, frameCount: number) {}

const Rarity = forwardRef<HTMLDivElement, Props>(
  ({ customImg, numBadges, canvasSize, setBadgeData }, ref) => {
    function useStateWithUpdateBadgeData<T>(
      defaultValue: T,
      key: string
    ): [T, (obj: T) => void] {
      const [objData, setObjData] = useState<T>(defaultValue);

      useEffect(() => {
        return () => {
          // do cleanup
        };
      }, [objData]);

      const customSetObject = useCallback((newObjData: T) => {
        setObjData(() => {
          setBadgeData({ [key]: newObjData });
          return newObjData;
        });
      }, []);

      return [objData, customSetObject];
    }
    const [fontFillColor, setFontFillColor] =
      useStateWithUpdateBadgeData<RGBAColor>(
        {
          r: 0,
          g: 0,
          b: 0,
          a: 1,
        },
        "fontFillColor"
      );
    const [fontStrokeColor, setFontStrokeColor] =
      useStateWithUpdateBadgeData<RGBAColor>(
        {
          r: 255,
          g: 255,
          b: 255,
          a: 1,
        },
        "fontStrokeColor"
      );
    const [bgColor, setBgColor] = useStateWithUpdateBadgeData<RGBAColor>(
      {
        r: 255,
        g: 255,
        b: 255,
        a: 1,
      },
      "bgColor"
    );

    const [commonColor, setCommonColor] = useState<RGBAColor>({
      r: 224,
      g: 224,
      b: 224,
      a: 1,
    });
    const [uncommonColor, setUncommonColor] = useState<RGBAColor>({
      r: 51,
      g: 166,
      b: 255,
      a: 1,
    });
    const [rareColor, setRareColor] = useState<RGBAColor>({
      r: 255,
      g: 238,
      b: 46,
      a: 1,
    });
    const [percentCommon, setPercentCommon] = useState<number>(50);

    const onPercentCommonChanged = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        setPercentCommon(e.target.valueAsNumber);
      },
      []
    );
    const [hugImage, setHugImage] = useStateWithUpdateBadgeData<boolean>(
      false,
      "hugCustomImage"
    );

    const percentUncommon = (100 - percentCommon) / 2;
    const percentRare = ((100 - percentCommon - percentUncommon) * 3) / 4;
    const percentLegendary = (100 - percentCommon - percentUncommon) / 4;

    const numberCommon = Math.floor((numBadges * percentCommon) / 100);
    const numberUncommon = Math.floor((numBadges * percentUncommon) / 100);
    const numberRare = Math.floor((numBadges * percentRare) / 100);
    const numberLegendary =
      numBadges - numberRare - numberUncommon - numberCommon;

    return (
      <div ref={ref} className={cx(styles.card)}>
        <div className={cx(styles.rarityShelf)}>
          {customImg?.src != null && (
            <div
              className={cx(styles.imageCarousel, styles.imageCarouselMobile)}
            >
              <div className="imgDisplay-row">
                <div className="imgDisplay">
                  <CanvasImage
                    fontStrokeColor={fontStrokeColor}
                    size={canvasSize}
                    hugCustomImage={hugImage}
                    customImg={customImg}
                    customImgSrc={customImg.src}
                    fontFillColor={fontFillColor}
                    bgColor={bgColor}
                    paddingColor={commonColor}
                    imgNumber={1}
                  />
                  <div className="customColor">
                    <ColorPicker
                      pickerSize="small"
                      color={commonColor}
                      onChange={setCommonColor}
                    />
                    <Text>Common</Text>
                  </div>
                </div>
                <div className="imgDisplay">
                  <CanvasImage
                    fontStrokeColor={fontStrokeColor}
                    size={canvasSize}
                    hugCustomImage={hugImage}
                    customImg={customImg}
                    customImgSrc={customImg.src}
                    fontFillColor={fontFillColor}
                    bgColor={bgColor}
                    paddingColor={uncommonColor}
                    imgNumber={numberCommon + 1}
                  />
                  <div className="customColor">
                    <ColorPicker
                      pickerSize="small"
                      color={uncommonColor}
                      onChange={setUncommonColor}
                    />
                    <Text>Uncommon</Text>
                  </div>
                </div>
              </div>
              <div className="imgDisplay-row">
                <div className="imgDisplay">
                  <CanvasImage
                    fontStrokeColor={fontStrokeColor}
                    hugCustomImage={hugImage}
                    size={canvasSize}
                    customImg={customImg}
                    customImgSrc={customImg.src}
                    fontFillColor={fontFillColor}
                    bgColor={bgColor}
                    paddingColor={rareColor}
                    imgNumber={numberUncommon + numberCommon + 1}
                  />
                  <div className="customColor">
                    <ColorPicker
                      pickerSize="small"
                      color={rareColor}
                      onChange={setRareColor}
                    />
                    <Text>Rare</Text>
                  </div>
                </div>
                <div className="imgDisplay">
                  <CanvasImage
                    fontStrokeColor={fontStrokeColor}
                    hugCustomImage={hugImage}
                    size={canvasSize}
                    customImg={customImg}
                    customImgSrc={customImg.src}
                    fontFillColor={fontFillColor}
                    bgColor={bgColor}
                    imgNumber={numberCommon + numberUncommon + numberRare + 1}
                    legendary
                  />
                  <div className="customColor">
                    <Text>Legendary</Text>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className={cx(styles.rarityControls)}>
            <div className={cx(styles.colorControls)}>
              {/* <div className={cx(styles.pickers)}>
                <ColorPicker
                  color={fontStrokeColor}
                  onChange={setFontStrokeColor}
                />
                <Text> Font stroke color </Text>
              </div> */}
              <div className={cx(styles.pickers)}>
                <ColorPicker
                  color={fontFillColor}
                  onChange={setFontFillColor}
                />
                <Text> Font color </Text>
              </div>
              {/* <div className={cx(styles.pickers)}>
                <ColorPicker color={bgColor} onChange={setBgColor} />
                <Text> Background color </Text>
              </div> */}
              <Checkbox
                checked={hugImage}
                onChange={(e) => {
                  setHugImage(e.currentTarget.checked);
                }}
                className={cx(styles.pickers)}
              >
                <Text>Hug Image</Text>
              </Checkbox>
            </div>
            <div className={cx(styles.percentages)}>
              <Text>
                <InlineInput
                  value={percentCommon}
                  onChange={onPercentCommonChanged}
                  min={0}
                  max={50}
                  afterContent="%"
                />
                of your fans ({numberCommon}) will get <b>common</b> badges
              </Text>
              <Text>
                <b>{percentUncommon.toFixed(2).replace(/[.,]00$/, "")}%</b> of
                your fans ({numberUncommon}) will get <b>uncommon</b> badges
              </Text>
              <Text>
                <b>{percentRare.toFixed(2).replace(/[.,]00$/, "")}%</b> of your
                fans ({numberRare}) will get <b>rare</b> badges
              </Text>
              <Text>
                <b>{percentLegendary.toFixed(2).replace(/[.,]00$/, "")}%</b> of
                your fans ({numberLegendary}) will get <b>legendary</b> badges
              </Text>
            </div>
          </div>
        </div>
        <div></div>
      </div>
    );
  }
);

const styles = {
  card: css`
    margin-bottom: 24px;
  `,
  imageCarousel: css`
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: 48px 0;

    & > .imgDisplay-row {
      display: flex;
      flex-grow: 1;
      & > .imgDisplay {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        align-items: center;

        .imgDisplay-row {
          display: flex;
        }
      }
    }
    & .customColor {
      font-weight: 500;
      display: flex;
      align-items: center;
      margin-top: 12px;
      & > .badger-colorPicker {
        margin-right: 8px;
      }
    }
  `,
  imageCarouselMobile: until(
    "tablet",
    css`
      justify-content: center;
      margin: 24px 0;
      & > .imgDisplay-row {
        display: block;
        & > .imgDisplay {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-bottom: 24px;
        }
      }
    `
  ),
  rarityShelf: css`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
  `,
  pickers: css`
    display: flex;

    & > div,
    & > input[type="checkbox"] {
      margin-right: 12px;
    }
    flex-direction: row;
    align-items: center;

    &.checkbox {
      display: flex;
      margin-left: 15px;
    }
    font-weight: 500;
  `,
  percentages: css`
    display: flex;
    flex-direction: column;
    & b {
      font-weight: 500;
    }
    & .badger-text {
      margin-bottom: 8px;
    }
  `,
  rarityControls: css`
    display: flex;
  `,
  colorControls: css`
    display: flex;
    flex-direction: column;
    margin-right: 24px;
    & > div {
      margin-bottom: 12px;
    }
  `,
};

export default Rarity;
