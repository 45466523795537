import React, { useRef, useEffect, useState } from "react";
import { cx, css } from "@emotion/css/macro";
import HeaderItem from "@shared/components/header/HeaderItem";
import BaseHeader from "@shared/components/header/BaseHeader";
import { scrollToRef, getRefTop } from "@shared/utils/utils";
import colors from "@shared/styles/colors.scss";
import { sendEvent } from "@shared/utils/analytics";
import { until } from "@shared/styles/mediaQueries";
import FollowLink from "./twitter/Follow";
import { Twitter } from "react-feather";
import JoinLink from "./discord/JoinLink";
import DiscordSVG from "./discord/DiscordSVG";

type Props = {
  notifySectionRef: React.RefObject<HTMLDivElement>;
  faqSectionRef: React.RefObject<HTMLDivElement>;
  aboutSectionRef: React.RefObject<HTMLDivElement>;
  createSectionRef: React.RefObject<HTMLDivElement>;
  ref: React.RefObject<HTMLDivElement>;
};

const headerItemClickEvent = "header-item-click";

function Header({
  faqSectionRef,
  aboutSectionRef,
  createSectionRef,
  ref,
}: Props) {
  const headerRef = ref;
  const firstAnimationFired = useRef(false);
  const [scrollTop, setScrollTop] = useState(999999);
  const [colorState, setColorState] = useState(0);
  useEffect(() => {
    const onScroll = (e: Event) => {
      const headerHeight = headerRef?.current?.offsetHeight || 0;
      // if we haven't fired an animation yet, and it's in the dark,
      // skip the first load so it doesn't auto trigger the animation
      if (
        !firstAnimationFired.current &&
        document.documentElement.scrollTop + headerHeight <
          getRefTop(createSectionRef)
      ) {
        setScrollTop(document.documentElement.scrollTop);
        return;
      }
      if (
        document.documentElement.scrollTop + headerHeight <
          getRefTop(createSectionRef) &&
        (scrollTop > getRefTop(createSectionRef) || colorState >= 0)
      ) {
        firstAnimationFired.current = true;
        setColorState(-1);
      }
      if (
        document.documentElement.scrollTop > getRefTop(createSectionRef) &&
        (scrollTop < getRefTop(createSectionRef) || colorState <= 0)
      ) {
        firstAnimationFired.current = true;
        setColorState(1);
      }
      setScrollTop(document.documentElement.scrollTop);
    };
    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, [scrollTop, colorState]);
  return (
    <BaseHeader
      className={cx(
        "is-launch",
        colorState > 0 && "launch-animate-forward",
        colorState < 0 && "launch-animate-backward"
      )}
    >
      <HeaderItem
        className={cx()}
        onClick={() => {
          scrollToRef(createSectionRef, headerRef);
          sendEvent(headerItemClickEvent, { name: "demo" });
        }}
      >
        Demo
      </HeaderItem>
      <HeaderItem
        className={cx()}
        onClick={() => {
          scrollToRef(faqSectionRef, headerRef);
          sendEvent(headerItemClickEvent, { name: "faq" });
        }}
      >
        F.A.Q
      </HeaderItem>
      <HeaderItem
        className={cx()}
        onClick={() => {
          scrollToRef(aboutSectionRef, headerRef);
          sendEvent(headerItemClickEvent, { name: "about" });
        }}
      >
        About
      </HeaderItem>
      <HeaderItem onClick={() => {}}>
        <FollowLink>
          <Twitter></Twitter>
        </FollowLink>
      </HeaderItem>
      <HeaderItem onClick={() => {}}>
        <JoinLink>
          <DiscordSVG className={cx(styles.discord)}></DiscordSVG>
        </JoinLink>
      </HeaderItem>
    </BaseHeader>
  );
}

const styles = {
  hideWhenVertical: until(
    "tablet",
    css`
      display: none;
    `
  ),
  discord: css`
    width: 25px;
    stroke-width: 4px;
    stroke: ${colors.discord};
    fill: #ffffff;
  `,
};

export default Header;
