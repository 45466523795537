import {
  DISCORD_INVITE_URL,
  FOLLOW_US_URL,
  TWEET_AT_CREATORS_URL,
} from "./constants";

export const faq: {
  [key: string]: {
    q: string;
    a: string[];
  };
} = {
  how_work: {
    q: "How does this work?",
    a: [
      `NiftyBadger makes it simple for creators to build a loyal and invested community.`,

      `**Create -** create and launch badges as a [collection of NFT art](#collection).`,
      `**Collect -** your fans [mint digital art](#mint) and you receive payment upfront, plus royalties from resales.`,
      `**Experience -** badge owners get [exclusive access to your fan community](#discord) and to your livestreams, all through our platform.`,
    ],
  },
  what_badge: {
    q: "What is a badge?",
    a: [
      `Think of a badge as a souped-up concert ticket. Not only does it let someone in, it also lets them join an exclusive community of fans and supporters, all while being represented as a piece of collectible digital art.`,
      `Each badge is a non-fungible token (NFT) on the [Polygon blockchain](https://solana.com/), and can be traded on any Ethereum marketplace. [Polygon is energy-efficient](https://solana.com/news/solana-energy-usage-report-november-2021) and incredibly performant, which means cheaper service fees and more money in your pocket.`,
    ],
  },

  scam: {
    q: `I’ve heard about NFTs and they seem like a scam, why should I trust you?`,
    a: [
      `We built NiftyBadger to make NFTs more practical and less speculative - badges are immediately useful as a replacement for subscriptions, and will continue to grow more valuable as we develop [integrations with other applications](#discord).`,
      `Our mission is to put creators and fans first by creating and growing communities based on **functional art**. We charge lower service fees so that creators earn more, and they receive royalties on resales which protects against scalpers.`,
    ],
  },

  why_us: {
    q: `Why wouldn’t I just use an existing creator platform?`,
    a: [
      `**Community -** NiftyBadger integrates and helps create a Discord community for each creator where they can connect with their fans.`,
      `**Price -** Community management will always be free, and we only take a [small percentage](#pricing) of any badge sales you make.`,
      `**Rewards -** Badges incentivize fans to invest more within the community. Each badge is an entry pass, so early supporters share in the rewards as their favorite creators grow in popularity.`,
    ],
  },

  for_creators: {
    q: `I’m a creator, how does this benefit me?`,
    a: [
      `We believe that our platform should augment creators and help them provide better experiences for their fans.`,
      `[Our fees are lower](#pricing) than subscription platforms like YouTube or Twitch, and we put this money directly in your pocket. Badges also have built-in royalties, so you get paid whenever badge is resold or scalped.`,
      `NiftyBadger accepts [cryptocurrency and Stripe payments](#mint), and our integrations use badges to power unique experiences across the internet that will delight and grow your fanbase - think secret Shopify stores and exclusive audio channels.`,
    ],
  },

  for_fans: {
    q: `I’m a fan, how does this benefit me?`,
    a: [
      `Badges are more than just subscriptions - each one unlocks exclusive experiences, whether it’s access to a [fan community](#discord), a creator hype page, or a secret merch store.`,
      `We believe supporters should be rewarded as a creator’s popularity increases. Unlike QR codes, [NFT badges](#collection) rise in value as the community becomes more popular.`,
      `Finally, with our [lower platform fees](#pricing), we cut out pesky middlemen and give more to the people who deserve it.`,
    ],
  },
  pricing: {
    q: `What's the pricing model?`,
    a: [
      `We charge a **flat 10%** on the price of each badge plus a **10% royalty** on badges that are resold. For example, if you list a collection of badges for $20 each with a 25% royalty, NiftyBadger would earn $2 per sale and you would pocket $18. If the badge is resold later for $50, you would receive $12.50 and NiftyBadger would receive $5.`,
    ],
  },

  when: {
    q: "This is awesome! When’s the launch?",
    a: [
      `We're glad you asked! The team at NiftyBadger is working pretty much 24/7 to bring this product to life. We're aiming to launch a closed beta to [interested creators (know any?)](${TWEET_AT_CREATORS_URL}) in February 2022.`,
      `Following the beta, our public launch is planned for mid-March. We truly believe in community-driven products, so [follow us on Twitter](${FOLLOW_US_URL}) or [join our Discord](${DISCORD_INVITE_URL}) any time - we're always looking for feedback!`,
    ],
  },
  //   why_blockchain: {
  //     q: "Why does this even need the blockchain?",
  //     a: ["Content TBD"],
  //   },
  //   scalping: {
  //     q: 'What do you mean by “scalp protection"?',
  //     a: ["Content TBD"],
  //   },
  //   royalties: {
  //     q: "How do royalties work?",
  //     a: ["Content TBD"],
  //   },
  // badges: {
  //   q: "What can you do with your badge?",
  //   a: "Blockchain nft event Blockchain nft event Blockchain nft event Blockchain nft event Blockchain nft event Blockchain nft event Blockchain nft event Blockchain nft event Blockchain nft event Blockchain nft event Blockchain nft event Blockchain nft event",
  // },
  // solana: {
  //   q: "What blockchain does this use?",
  //   a: "Blockchain nft event Blockchain nft event Blockchain nft event Blockchain nft event Blockchain nft event Blockchain nft event Blockchain nft event Blockchain nft event Blockchain nft event Blockchain nft event Blockchain nft event Blockchain nft event",
  // },
  // arweave: {
  //   q: "Where is my badge stored? (arweave)",
  //   a: "Blockchain nft event Blockchain nft event Blockchain nft event Blockchain nft event Blockchain nft event Blockchain nft event Blockchain nft event Blockchain nft event Blockchain nft event Blockchain nft event Blockchain nft event Blockchain nft event",
  // },
};
