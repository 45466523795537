import amplitude from "amplitude-js";

export const init = () => {
  amplitude.getInstance().init(process.env.REACT_APP_AMPLITUDE_API_KEY!);
};

export const setUserId = (userId: string) => {
  amplitude.getInstance().setUserId(userId);
};

export const setProperties = (properties: any) => {
  amplitude.getInstance().setUserProperties(properties);
};

export const sendEvent = (eventType: string, eventProperties: any) => {
  amplitude.getInstance().logEvent(eventType, eventProperties);
};
