import React from "react";
import { cx, css } from "@emotion/css/macro";
import * as Icon from "react-feather";
import Text from "./Text";
import { sendEvent } from "../utils/analytics";
import HeaderSpacer from "./header/HeaderSpacer";

const footerTwitterEvent = "footer-twitter-click";

function Footer() {
  return (
    <footer className={cx(styles.fullWidth, "footer")}>
      <div className={cx(styles.footer)}>
        <HeaderSpacer />
        <div className={cx(styles.middle)}>
          <Text>© 2022 NiftyBadger, Inc.</Text>
          <Text className={cx(styles.smallText)}>Made with ☀️ in SF</Text>
        </div>
        <HeaderSpacer />
        <span className="icon">
          <a
            onClick={() => sendEvent(footerTwitterEvent, {})}
            href="https://twitter.com/niftybadger"
            target="_blank"
          >
            {" "}
            <Icon.Twitter />
          </a>
        </span>
      </div>
    </footer>
  );
}

const styles = {
  fullWidth: css`
    width: 100%;
    border-top: 1px solid #dbdae1;
  `,
  footer: css`
    & {
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
    & > * {
      margin-right: 12px;
    }
    & > *:last-child {
      margin-right: 0;
    }
  `,
  middle: css`
    & {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  `,
  smallText: css`
    font-size: 14px;
  `,
};

export default Footer;
