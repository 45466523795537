import React, {
  forwardRef,
  useEffect,
  useRef,
  useState,
  useCallback,
  RefObject,
} from "react";
import { cx, css, ClassNamesArg } from "@emotion/css/macro";
import Text from "@shared/components/Text";
import Input from "@shared/components/inputs/Input";
import Button from "@shared/components/button/Button";
import Royalties from "./Royalties";
import Rarity from "./Rarity";
import { APIClient } from "@shared/utils/api_client";
import { until } from "@shared/styles/mediaQueries";
import {
  BADGER_ICON_URL,
  CANVAS_SIZE_FOR_TABLET,
  CANVAS_SIZE_FOR_ALL_ELSE,
} from "../../../utils/constants";
import { jsUntil } from "@shared/styles/mediaQueries";
import { useThrottleCallback } from "@react-hook/throttle";
import DiscordButton from "../../discord/DiscordButton";

import type { BadgeDataType } from "../../../App";
import { ArrowDownCircle, ChevronsDown } from "react-feather";

type Props = {
  setBadgeData: (obj: BadgeDataType) => void;
  onNext: () => void;
};

const getAverageRGB = (
  imgEl: HTMLImageElement,
  maxNorm: number
): [number, number, number][] => {
  const blockSize = 5;
  const canvas = document.createElement("canvas");
  const context = canvas.getContext && canvas.getContext("2d");
  if (!context) {
    return [
      [255, 255, 255],
      [255, 255, 255],
    ];
  }

  canvas.height = imgEl.height;
  canvas.width = imgEl.width;
  context.drawImage(imgEl, 0, 0);

  const data = context.getImageData(0, 0, imgEl.width, imgEl.height);
  const length = data.data.length;

  let red = 0,
    green = 0,
    blue = 0,
    count = 0;
  for (let i = 0; i + blockSize * 4 + 2 < length; i += blockSize * 4) {
    count += 1;
    red += data.data[i];
    green += data.data[i + 1];
    blue += data.data[i + 2];
  }

  // ~~ used to floor values
  red = Math.floor(red / count);
  green = Math.floor(green / count);
  blue = Math.floor(blue / count);
  const normalizeFactor = maxNorm / Math.max(red, green, blue);
  [red, green, blue] = [
    Math.floor(red * normalizeFactor),
    Math.floor(green * normalizeFactor),
    Math.floor(blue * normalizeFactor),
  ];
  const [r2, g2, b2] = [
    Math.floor((255 + red) * 0.58),
    Math.floor((255 + green) * 0.58),
    Math.floor((255 + blue) * 0.58),
  ];
  return [
    [red, green, blue],
    [r2, g2, b2],
  ];
};

const Create = forwardRef<HTMLDivElement, Props>(
  ({ setBadgeData, onNext }, ref) => {
    const [customImgSrc, setCustomImgSrc] = useState<string>();
    const [canvasSize, setCanvasSize] = useState<number>(
      jsUntil("tablet") ? CANVAS_SIZE_FOR_TABLET : CANVAS_SIZE_FOR_ALL_ELSE
    );
    const customImageForCanvas = useRef(new Image());
    const [numBadges, setNumBadges] = useState(100);
    const changeColor = useRef(css``);
    const hasUploaded = useRef(false);
    const onCustomImgUpdated = useCallback(
      (img: HTMLImageElement) => {
        const [[r, g, b], [r2, g2, b2]] = getAverageRGB(img, 230);
        changeColor.current = css`
          background: linear-gradient(
            180deg,
            rgb(255, 255, 255),
            rgb(255, 255, 255),
            rgb(${r2}, ${g2}, ${b2}),
            rgb(${r}, ${g}, ${b})
          ) !important;
        `;
        hasUploaded.current = true;
        setCustomImgSrc(() => {
          customImageForCanvas.current.src = img.src;
          customImageForCanvas.current.width = canvasSize;
          customImageForCanvas.current.height = canvasSize;
          return img.src;
        });
        setBadgeData({
          customImg: customImageForCanvas.current,
          customImgSrc: img.src,
        });
      },
      [canvasSize, setBadgeData]
    );

    const resizeHandler = useThrottleCallback((e: Event) => {
      const isTablet = jsUntil("tablet");
      if (isTablet && canvasSize !== CANVAS_SIZE_FOR_TABLET) {
        setCanvasSize(CANVAS_SIZE_FOR_TABLET);
      } else if (!isTablet && canvasSize !== CANVAS_SIZE_FOR_ALL_ELSE) {
        setCanvasSize(CANVAS_SIZE_FOR_ALL_ELSE);
      }
    }, 6);

    useEffect(() => {
      window.addEventListener("resize", resizeHandler);
      return () => window.removeEventListener("resize", resizeHandler);
    }, [canvasSize, resizeHandler]);

    useEffect(() => {
      function setupInitialBadger() {
        setCustomImgSrc(BADGER_ICON_URL);
        setBadgeData({
          customImgSrc: BADGER_ICON_URL,
          customImg: customImageForCanvas.current,
        });
      }
      customImageForCanvas.current.src = BADGER_ICON_URL;
      customImageForCanvas.current.addEventListener("load", setupInitialBadger);
      return () => {
        customImageForCanvas.current.removeEventListener(
          "load",
          setupInitialBadger
        );
      };
    }, []);

    return (
      <div ref={ref} className={cx("card", styles.card, changeColor.current)}>
        <Text className={cx(styles.title)} type="h3">
          Drop the hottest collection of the year
        </Text>
        <Text type="subtitle">
          Skip the subscription. Give your fans a badge they'll cherish and
          collect.
        </Text>
        <div className={cx(styles.temp, styles.tempVertical)}>
          <Royalties
            numBadges={numBadges}
            onCustomImgUpdated={onCustomImgUpdated}
            onNumBadgesUpdated={setNumBadges}
          />
          {hasUploaded.current ? (
            <Rarity
              customImg={customImageForCanvas.current}
              canvasSize={canvasSize}
              numBadges={numBadges}
              setBadgeData={setBadgeData}
            />
          ) : null}
          <span
            className={cx(styles.next)}
            onClick={() => {
              onNext();
              APIClient().post("/landing", {
                data: {
                  test: 1,
                  hello: "world!",
                },
              });
            }}
          >
            <ChevronsDown size={30} />
          </span>
        </div>
      </div>
    );
  }
);

const styles = {
  card: css`
    background-color: white;
    border-radius: 18px !important;
    width: calc(80%);
    margin: auto;
    margin-top: -300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2em;
  `,
  temp: css`
    display: flex;
    flex-direction: column;
  `,
  tempVertical: until(
    "tablet",
    css`
      width: 100%;
    `
  ),
  tempSpacer: css`
    flex-grow: 1;
  `,
  title: css`
    margin-bottom: 6px;
  `,
  next: css`
    width: 100%;
    text-align: center;
    animation-duration: 1.6s;
    animation-name: iconArrowBounceDownBlack;
    animation-iteration-count: infinite;
    &:hover {
      cursor: pointer;
    }
  `,
};

export default Create;
