import React, { PropsWithChildren, useRef } from "react";
import { cx, css, ClassNamesArg } from "@emotion/css/macro";
import StickyHeader from "./StickyHeader";
import HeaderItem from "./HeaderItem";
import HeaderSpacer from "./HeaderSpacer";
import Logo from "../logo/Logo";
import { sendEvent } from "../../utils/analytics";

type Props = PropsWithChildren<{
  className?: ClassNamesArg;
}>;

const baseHeaderClickEvent = "base-header-click";

function BaseHeader({ children, className }: Props) {
  const headerRef = useRef<HTMLDivElement>(null);
  return (
    <StickyHeader ref={headerRef} className={className}>
      <HeaderItem
        onClick={() => {
          window.history.pushState({}, "", "/");
          sendEvent(baseHeaderClickEvent, {});
        }}
      >
        <Logo type="dynamic" />
      </HeaderItem>
      <HeaderSpacer />
      {children}
    </StickyHeader>
  );
}

export default BaseHeader;
