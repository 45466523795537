import React, { forwardRef } from "react";
import { cx, css, ClassNamesArg } from "@emotion/css/macro";
import { until } from "@shared/styles/mediaQueries";
import Text from "@shared/components/Text";
import ProfileCard from "../ProfileCard";

type Props = { className: ClassNamesArg };
const About = forwardRef<HTMLDivElement, Props>(({ className }, ref) => (
  <div ref={ref} className={cx(className, styles.container)}>
    <Text className={cx(styles.header)} type="h3">
      About us
    </Text>
    <div className={cx(styles.cards, styles.cardsVertical)}>
      <ProfileCard
        linkedinUrl="https://www.linkedin.com/in/boyang-niu"
        twitterUrl="https://www.twitter.com/kumquatexpress"
        githubUrl="https://www.github.com/kumquatexpress"
        profileImg="https://bobo-public.s3.us-west-1.amazonaws.com/neopet.png"
        title="@kumquatexpress"
        body="Spends most of his time confusing redis with reddit"
      />
      <span className={cx(styles.spacer)}></span>
      <ProfileCard
        linkedinUrl="https://www.linkedin.com/in/davidbxu"
        twitterUrl="https://www.twitter.com/wobsobby"
        githubUrl="https://www.github.com/dxu"
        profileImg="https://bobo-public.s3.us-west-1.amazonaws.com/dxuneopet.png"
        title="@wobsobby"
        body="Streams himself coding to tap into that exhibitionist energy"
      />
    </div>
  </div>
));

const styles = {
  container: css``,
  header: css`
    margin-bottom: 24px;
  `,
  cards: css`
    display: flex;
    flex-direction: row;
    justify-content: center;
  `,
  cardsVertical: until(
    "tablet",
    css`
      flex-direction: column;
      justify-content: space-evenly;
    `
  ),
  spacer: css`
    width: 0px;
    height: 3em;
  `,
};

export default About;
