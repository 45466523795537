import React, { useEffect, useCallback, useState, useRef } from "react";
import { cx, css, ClassNamesArg } from "@emotion/css/macro";
import { DropTargetError } from "@shared/components/inputs/DndFileUploader";
import DndFileUploader from "@shared/components/inputs/DndFileUploader";
import * as Icon from "react-feather";
import colors from "@shared/styles/colors.scss";
import { BADGER_ICON_URL } from "../../../utils/constants";

type Props = { onCustomImgUpdated: (imageElement: HTMLImageElement) => void };
const fr = new FileReader();

const RoyaltiesDropTarget = ({ onCustomImgUpdated }: Props) => {
  const [isActive, setIsActive] = useState(false);
  const [error, setError] = useState<DropTargetError | null>(null);
  const [file, setFile] = useState<File | null>(null);
  const [imgLoaded, setImgLoaded] = useState(false);
  const [tried, setTried] = useState(false);
  const imgRef = useRef<HTMLImageElement>(null);
  const manualInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    function handleImageOnload() {
      console.log("image loaded");
      onCustomImgUpdated(imgRef.current as HTMLImageElement);
    }
    function handleFileReader() {
      // convert image file to base64 string
      if (imgRef.current != null && fr.result != null) {
        imgRef.current.src = fr.result as string;
        imgRef.current.addEventListener("load", handleImageOnload);
        setImgLoaded(true);
      }
    }
    fr.addEventListener("load", handleFileReader, false);
    return () => {
      fr.removeEventListener("load", handleFileReader);
      imgRef.current?.removeEventListener("load", handleImageOnload);
    }; // ... and to false on unmount
  }, []);

  const setFileStateAndLoadImage = useCallback(
    (file) => {
      setFile(file);
      setImgLoaded(false);
      setTried(true);
      fr.readAsDataURL(file);
    },
    [setFile]
  );

  const handleManualInputOnChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (
        manualInputRef.current?.files &&
        manualInputRef.current?.files[0] != null
      ) {
        setFileStateAndLoadImage(manualInputRef.current?.files[0]);
      }
    },
    []
  );

  const dropIsActive = isActive && error == null;

  return (
    <div className={cx("badger-royaltiesDropTarget", styles.container)}>
      <DndFileUploader
        className={cx(styles.dndUploadWrapper)}
        dropTarget={
          <div
            className={cx(
              styles.inputFilePicker,
              dropIsActive && "dnd-fileactive",
              "has-tooltip-arrow",
              "has-tooltip-active",
              "has-tooltip-left"
            )}
            data-tooltip={!tried ? "Try me!" : null}
          >
            <label
              htmlFor="file-upload"
              className={cx(styles.inputFilePickerTarget)}
            >
              <div className={cx(styles.inputFilePickerTargetDropPreview)}>
                {"Drop it here :)"}
              </div>
              <img
                className={cx(styles.image, !imgLoaded && styles.imageHidden)}
                ref={imgRef}
              />
              <div
                className={cx(
                  styles.inputFilePickerControls,
                  imgLoaded && styles.inputFilePickerControlsHidden
                )}
              >
                {!dropIsActive && <Icon.Image size={50} />}
              </div>
            </label>
            <input
              id="file-upload"
              ref={manualInputRef}
              type="file"
              accept="image/png, image/jpeg"
              onChange={handleManualInputOnChange}
            />
          </div>
        }
        onError={setError}
        onSuccess={setFileStateAndLoadImage}
        onIsActiveChanged={setIsActive}
      ></DndFileUploader>
    </div>
  );
};

const styles = {
  container: css`
    align-self: center;
    width: 250px;
    height: 250px;
    flex-shrink: 0;
    display: flex;
    cursor: pointer;
  `,
  dndUploadWrapper: css`
    width: 100%;
    height: 100%;
  `,
  defaultBadger: css`
    width: 100%;
    height: 100%;
    background-color: red;
  `,
  itemDropped: css`
    width: 100%;
    height: 100%;
    background-color: green;
  `,
  imageHidden: css`
    display: none;
  `,
  inputFilePicker: css`
    width: 100%;
    height: 100%;
    input[type="file"] {
      display: none;
    }

    box-shadow: 0px 0px 0px 1px ${colors.badgerBlue};
    transition: box-shadow 0.05s linear, opacity 0.05s linear;

    &:hover {
      box-shadow: 0px 0px 0px 2px ${colors.badgerBlue};
      opacity: 1;
    }

    &.has-tooltip-active:before {
      animation-duration: 0.8s;
      animation-name: tooltipBounce;
      animation-iteration-count: infinite;
      animation-direction: alternate;
    }

    &.has-tooltip-active:after {
      animation-duration: 0.8s;
      animation-name: tooltipArrowBounce;
      animation-iteration-count: infinite;
      animation-direction: alternate;
    }
  `,
  inputFilePickerActive: css``,
  inputFilePickerControls: css`
    width: 100%;
    height: 100%;
    padding: 6px 12px;
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.05);
    opacity: 0.6;

    &::before {
      content: "";
      background-image: url(${BADGER_ICON_URL});
      background-size: cover;
      position: absolute;
      top: 0px;
      right: 0px;
      bottom: 0px;
      left: 0px;
      opacity: 0.1;
    }
  `,

  inputFilePickerControlsHidden: css`
    display: none;
  `,

  inputFilePickerTarget: css`
    width: 100%;
    height: 100%;
    display: flex;
    position: relative;
  `,

  inputFilePickerTargetDropPreview: css`
    display: none;
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    .dnd-fileactive & {
      display: flex;
      transition: box-shadow 0.05s linear, opacity 0.05s linear;
      box-shadow: 0px 0px 0px 2px ${colors.badgerBlue};
      opacity: 1;
    }
  `,

  image: css`
    cursor: pointer;
    .dnd-fileactive & {
      opacity: 0.1;
    }
  `,
};

export default RoyaltiesDropTarget;
