import React from "react";
import { cx, css, ClassNamesArg } from "@emotion/css";

type TextAreaColor =
  | "primary"
  | "link"
  | "info"
  | "info"
  | "warning"
  | "danger";

type TextAreaSize = "small" | "normal" | "medium" | "large";

type Props = {
  className?: ClassNamesArg;
  rows?: number;
  color?: TextAreaColor;
  placeholder?: string;
  round?: boolean;
  size?: TextAreaSize;
  disabled?: boolean;
  readonly?: boolean;
  fixedSize?: boolean;
  isStatic?: boolean;
  name?: string;
  id?: string;
  loading?: boolean;
  maxLength?: number;
  value?: string;
};

function TextArea({
  color,
  name,
  id,
  size = "normal",
  round = false,
  isStatic = false,
  loading = false,
  placeholder,
  rows,
  fixedSize,
  maxLength,
  className,
  value,
  ...props
}: Props) {
  return (
    <div
      className={cx("control", size && `is-${size}`, loading && `is-loading`)}
    >
      <textarea
        className={cx(
          "textarea",
          size && `is-${size}`,
          color && `is-${color}`,
          round && "is-rounded",
          isStatic && "is-static",
          fixedSize && "has-fixed-size",
          className
        )}
        id={id}
        name={name}
        value={value}
        placeholder={placeholder}
        maxLength={maxLength}
        {...props}
      />
    </div>
  );
}

const styles = {};

export default TextArea;
